import React from "react";

import SEO from "@components/seo";
import WarningBanner from "@components/WarningBanner";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <WarningBanner />
  </>
);

export default NotFoundPage;
